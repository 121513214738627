<template>
  <div class="Orderlist">
    <div class="top-title">
      <div class="top-text">商家费用</div>
      
        <el-form ref="form" label-width="100px">
          <div class="top-box">
 
      <div class="Newsinvite-form">
          <el-form-item label="商家名称">
            <el-select v-model="seeks.a" placeholder="请选择商家名称">
              <el-option
                v-for="(r, i) in lists"
                :key="i"
                :label="r.companyName"
                :value="r.id"
              ></el-option
            ></el-select>
          </el-form-item>
        </div>


          <el-form-item label="年月筛选">
              <el-date-picker
                    v-model="seeks.b"  type="month"
                    format="yyyy 年 MM 月" value-format="yyyy-MM"
                    placeholder="选择月">
                  </el-date-picker>
           </el-form-item>


              <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="seek()"
                >搜索</el-button>
            </div>
        </el-form>
      
    </div>


    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" row-class-name="tableRowStyle" v-loading="loading">
        <el-table-column prop="bs_company_name" label="商家名称"> </el-table-column>
        <el-table-column prop="bsr_employername" label="工厂名称"> </el-table-column>
        <el-table-column prop="wkhours_sum" label="工时">
            <template slot-scope="scope">
               <span>{{parseFloat(scope.row.wkhours_sum).toFixed(1)}}</span>
            </template>
           </el-table-column>
        <el-table-column prop="feetotal_sum" label="总费用"> 
           <template slot-scope="scope">
               <span>{{parseFloat(scope.row.feetotal_sum).toFixed(2)}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="sbu_mobile" label="手机号"> </el-table-column>
        <el-table-column label="操作" width="200" class="table-form-btn">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              class="table-form-btn"  type="text"
              >详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
              <div style="font-size:18px;color:red;float:right;padding-right:50px">小计：{{feetotal_sum}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
export default {
  data() {
    return {

      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      loading: this.$common.loading,
feetotal_sum:'',
      // 下拉框
      seeks: {},
      classactive:0,

      list:[],
      lists:[],
    };
  },
  created() {
     this.getSong();
     this.getSongs();
  },
  mounted() {

  },

  methods: {


    // //查询接口
      async getSong(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        bizCatalog:'TodoWorkEntity',
      handleMode:'fetchallSupplierSettle',
        salary_status:0,
        commission_status:1,
        query:{
          servicerId:this.seeks.a,
          yearMonth:this.seeks.b,
        }, })
          
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.feetotal_sum = res.Result.feetotal_sum
           this.total = res.Result.count
           this.loading = false
      },


          //查询接口
      async getSongs(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: 1,
        pageSize: 500,
        bizCatalog:'ServicerPlaterA',
        query:{ statusCode:0,}, })

        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.lists = res.Result.pageData
      },


    // 跳转详情页
    handleClick(row) {
          this.$router.push({
          path: '/home/workorderpar',
          query: {
            id: row.workid,
          }
        })
    },
 

    // 搜索栏
    seek(){
      this.currentPage = 1;
        this.getSong();
    },




    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },
  },
  computed: {},
};
</script>


<style  scoped>
.table-tap{
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul{
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 150px;
  text-align: center;
}
.table-tap ul li span{
font-size: 18px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: rgb(255, 187, 0);
  font-size: 16px;
}
@import "../../assets/css/liststyle.css";
</style>